<template>
    <div style="width: 100%; height: 100%; position:absolute;">
        <div class="background-img" id="bg-se">
            <div class="loader">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
        <canvas id="unity-canvas" style="width: 1200px; height: 800px; background: #231F20" class="alturase"></canvas>

    </div>
</template>
<script>
import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'


export default {
    
    data() {
        return {
           token:'', 
        }
    },
    created(){
        console.log(this.$store.state.user);
        let role = this.$store.state.user.role;
        let uid = this.$store.state.user.uid;
        if (role == 'student') {
            let user = { uid: uid, role: "Student" }
            const updatetAdmin = firebase.functions().httpsCallable('IsExpirate')
            updatetAdmin(user)
                .then((result) => {
                // console.log("*******************************");
                //  console.log(result);
                    if (result.data.isVencido)
                         location.href = "/license-finish";
                });
        }
    },
    mounted() {
        this.token  = this.$store.state.user.token
        var elemento = document.querySelectorAll(".alturase");
        var height = window.innerHeight-50;
        var width = window.innerWidth;
        elemento[0].style.height=height+'px';
        elemento[0].style.width=width+'px';
        var unityGame; 
        let responsiveScript = document.createElement('script')
        responsiveScript.setAttribute('src', '/se/Build/oldweb11092023.loader.js')
        responsiveScript.onload = () => {
            createUnityInstance(document.querySelector("#unity-canvas"), {
                dataUrl: "/se/Build/oldweb11092023.data",
                frameworkUrl: "/se/Build/oldweb11092023.framework.js",
                codeUrl: "/se/Build/oldweb11092023.wasm",
                streamingAssetsUrl: "/se/StreamingAssets",
                companyName: "escueladelectura",
                productName: "LeeLee SE",
                productVersion: "1.0.16",
            })
            .then((instance) =>
            {
                window.unityInstance = instance;
                window.firebase = firebase;
                // this.unityInstance = instance;
                unityGame = instance;
                let me = this;
                var bg = document.getElementById('bg-se');
                if(bg != null){
                    bg.classList.add('d-none');
                }
                // setTimeout(function () { unityInstance.SendMessage('LogoControlador', 'WebGLFirebaseToken', me.token); console.log(me.token) }, 3000);
                
            })
            .catch((message) => { console.warn(message); });
        }
        document.body.appendChild(responsiveScript)
    },
}
</script>
<style>
  .background-img{
    width: 100%;
    height: calc(100% - 50px);
    background-image: url('/loadingimg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    right: 0;
    top: 45px;
    display: flex;
    z-index: 1;
  }
  .loader{
    position: absolute;
    bottom: 50px;
    left: 50%;
  }
</style>